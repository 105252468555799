<template>
  <div class="w-full bg-white min-h-screen mx-auto">
    <Navigation />
    <div class="max-w-7xl mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="OverView" />
        <div class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg shadow-lg">
          Status Page
        </div>  
      </div>  
    </div>  
  </div>
</template>
  
<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/DocumentLeftNavView.vue'
export default {
  name: 'DocumentView',
  components: {
    Navigation,
    LeftNavigation,
  },
  data(){
    return{
      documents : []
    }
  },
  created(){
    let Tracking = JSON.parse(localStorage.getItem('Tracking'))
    if(Tracking['document']==true){
      this.documents = JSON.parse(localStorage.getItem('studentInfo'))
      this.documents = this.documents.formsInfo.Documents
    }
  }
}
</script>
  