<template>
  <div class="w-full bg-white min-h-screen mx-auto">
    <Navigation />
    <div class="max-w-7xl mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="UnVerified" />
        <div v-if="this.studnetInfo" class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg p-6">
          <div v-if="this.basicInfo" class="flex flex-col gap-2 text-start">
            Student info
            <div class="flex flex-row text-xs rounded">
              <table class="w-full border">
                <thead  class="w-full">
                  <tr>
                    <th class="border-r py-1">S.No</th>
                    <th class="border-r py-1">Id</th>
                    <th class="border-r py-1">Name</th>
                    <th class="border-r py-1">Email</th>
                    <th class="border-r py-1">Phone</th>
                    <th class="border-r py-1">Program</th>
                    <th class="">Elective</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-t">
                    <td class="p-1 border-r">
                      1
                    </td>
                    <td class="p-1 border-r">
                      <router-link :to="{name: 'DocumentDetailView', params: {id: this.studnetInfo.student_id==true ? this.studnetInfo.student_id : 'Test-id'}}">
                        {{ this.studnetInfo.student_id }}
                      </router-link>
                    </td>
                    <td class="p-1 border-r">
                      {{ this.basicInfo.full_name }}
                    </td>
                    <td class="p-1 border-r">
                      {{ this.basicInfo.email_address }}
                    </td>
                    <td class="p-1 border-r">
                      {{ this.basicInfo.country_code_primary }} {{ this.basicInfo.mobile_number_primary }}
                    </td>
                    <td class="p-1 border-r">
                      {{ this.basicInfo.program }}
                    </td>
                    <td class="p-1">
                      {{ this.basicInfo.elective }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            No application yet
          </div>

          <div class="flex flex-col gap-2 text-start">
            Uploaded Documents
            <div class="flex flex-row gap-3 text-xs flex-wrap ">
              <div v-if="this.documents.passport.file != ''" @click="selectFile('passport')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>Passprt size phone</b> 
              </div>
              <div v-if="this.documents.dob.file != ''" @click="selectFile('dob')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>Date of birth proof</b> 
              </div>
              <div v-if="this.documents.kyc.file != ''" @click="selectFile('kyc')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>KYC Document</b> 
              </div>
              <div v-if="this.documents.community.file != ''" @click="selectFile('community')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>Community Certificate</b> 
              </div>
              <div v-if="this.documents.secoundarymark.file != ''" @click="selectFile('secoundarymark')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>10 th Marksheet</b> 
              </div>
              <div v-if="this.documents.puemark.file != ''" @click="selectFile('puemark')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>12 th Marksheet</b> 
              </div>
              <div v-if="this.documents.consolitated.file != ''" @click="selectFile('consolitated')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>Ug Marksheet</b> 
              </div>
              <div v-if="this.documents.supportDoc.file != ''" @click="selectFile('supportDoc')" class="border border-gray-300 px-2 py-1 rounded whitespace-nowrap"> 
                <b>Other Supporting Document</b> 
              </div>
            </div>
          </div>
          <div v-if="this.assets.passport" class="flex flex-col text-start">
            Passprt size phone
            <div class="flex flex-col md:flex-row gap-2 text-xs">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.passport.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col justify-start items-start text-start gap-2 border border-gray-300 px-6 py-4 rounded-md">
                <label for="passportSizeComment" class="text-sm font-medium">Comment</label>  
                <textarea name="passport_size_comment" id="passportSizeComment" cols="30" rows="10" class="w-full px-2 py-1 border border-gray-300 rounded-md"></textarea>
                <div class="w-full flex flex-row py-2 justify-between">
                  <!-- <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span>  -->
                  <label for="dob_proof" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="dob_proof" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="this.assets.dob" class="flex flex-col text-start">
            Date of birth proof
            <div class="flex flex-col md:flex-row gap-4 text-xs">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.dob.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start">
                <div class="flex flex-row py-2 gap-2">
                  Date of birth as per Application (yyyy-mm-dd): <b>{{ this.basicInfo.dob }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="dob_proof" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="dob_proof" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.assets.kyc" class="flex flex-col text-start">
            KYC Document
            <div class="flex flex-col md:flex-row text-xs">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.kyc.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start p-4">
                <div class="flex flex-row py-2 gap-2">
                  Nationality as per Application: <b>{{ this.basicInfo.nationality }}</b> 
                </div>
                <div class="flex flex-row py-2 gap-2">
                  Date of birth as per Application (yyyy-mm-dd): <b>{{ this.basicInfo.dob }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="kyc_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="kyc_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
                <div v-if="this.basicInfo.nationality== 'Indian'" class="w-full">
                  <div class="flex flex-row py-2 gap-2">
                    Aadhar Number as per Application: <b>{{ this.basicInfo.aadhar_number }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                  <div class="flex flex-row py-2 gap-2">
                    Address as per Application: <b>{{ this.contacInfo.address_line_1 }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                </div>
                <div v-else class="w-full">
                  <div class="flex flex-row py-2 gap-2">
                    KYC type as per Application: <b>{{ this.basicInfo.kyc_name }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                  <div class="flex flex-row py-2 gap-2">
                    KYC Number as per Application: <b>{{ this.basicInfo.kyc_number }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="this.assets.community" class="flex flex-col text-start">
            Community Certificate
            <div class="flex flex-col md:flex-row text-xs gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.community.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start">
                <div class="flex flex-row py-2 gap-2">
                  Category as per Application: <b>{{ this.basicInfo.category }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="dob_proof" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="dob_proof" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.assets.secoundarymark" class="flex flex-col text-start border border-gray-300 p-3">
            <span class="py-2">10th Marksheet</span>
            <div class="flex flex-col md:flex-row gap-4 text-xs">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden p-3 bg-slate-100">
                <img :src="this.documents.secoundarymark.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start border border-gray-300 rounded-md p-3">

                <div class="flex flex-row py-2 gap-2">
                  School Name as per application: <b>{{ this.educationInfo.SecondarySchool }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Board Name as per application: <b>{{ this.educationInfo.SecondarySchoolBoard }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Year of Passing as per application: <b>{{ this.educationInfo.Secondaryyop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per application: <b>{{ this.educationInfo.Secondaryscheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  GPGA as per application: <b>{{ this.educationInfo.SecondaryCgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Image quality
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <select name="image_quality" id="imageQuality" v-model="secondaryMarkSheetQuality"
                    class="px-4 rounded-md bg-slate-200">
                    <option value="Bad">Bad</option>
                    <option value="Good">Good</option>
                    <option value="Better">Better</option>
                  </select>
                  <label v-if="secondaryMarkSheetQuality=='Bad'" for="secoundarymark_proof_5" 
                    class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as rejected
                  </label>
                  <label v-else for="secoundarymark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

              </div>
            </div>
          </div>

          <div v-if="this.assets.puemark" class="flex flex-col text-start">
            12 th Marksheet
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.puemark.file" alt="">
              </div>

              <div class="w-1/2 flex flex-col items-start text-xs">
                <div class="flex flex-row py-2 gap-2">
                  School Name as per Application: <b>{{ this.educationInfo.SecondarySchool }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Board Name as per Application: <b>{{ this.educationInfo.SecondarySchoolBoard }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Year of Passing as per Application: <b>{{ this.educationInfo.Secondaryyop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.Secondaryscheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  GPGA as per Application: <b>{{ this.educationInfo.SecondaryCgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.assets.consolitated" class="flex flex-col text-start">
            Ug Marksheet
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.degree.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start text-xs">
                <div class="flex flex-row py-2 gap-2">
                  College Name as per Application: <b>{{ this.educationInfo.college_name }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  University Name as per Application: <b>{{ this.educationInfo.university_name }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Degree as per Application: <b>{{ this.educationInfo.ug_degree }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.ug_yop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.ugmark_scheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  GPGA as per Application: <b>{{ this.educationInfo.ug_cgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.assets.supportDoc && this.documents.supportDoc.file != ''" class="flex flex-col text-start">
            Other Supporting Document
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.supportDoc.file" alt="">
              </div>
              <div class="w-1/2">

              </div>
            </div>
          </div>
        </div>  
        <div v-else>
          no data
        </div>
      </div>  
    </div>  
  </div>
</template>

<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/DocumentLeftNavView.vue'
export default {
  name: 'DocumentUnVerifiedView',
  components: {
    Navigation,
    LeftNavigation,
  },
  data(){
    return{
      // For uploaded document quality check
      secondaryMarkSheetQuality: 'Good',

      // For getting student date
      studnetInfo: {},
      documents : [],
      basicInfo: {},
      contacInfo: {},
      educationInfo: {},

      // For control the document detail view
      assets : {
      passport: false,
      dob: false,
      secoundarymark: true,
      puemark: false,
      consolitated: false,
      degree: false,
      kyc: false,
      community: false,
      supportDo : false
    }
    }
  },
  created(){
    let Tracking = JSON.parse(localStorage.getItem('Tracking'))
    if(Tracking['document']==true){
      this.studnetInfo = JSON.parse(localStorage.getItem('studentInfo'))
      console.log(this.studnetInfo)
      this.basicInfo = this.studnetInfo.formsInfo.basicInfo.basicInfo_formdata
      this.contacInfo = this.studnetInfo.formsInfo.contactInfo.contact_formdata
      this.educationInfo = this.studnetInfo.formsInfo.educationInfo.education_formdata
      this.documents = this.studnetInfo.formsInfo.Documents.document_formdata
    }
  },
  methods:{
    selectFile(name){
      console.log('test')
      if(name=='passport'){
        this.assets.passport = !this.assets.passport
        }else if (name=='dob'){
          this.assets.dob = !this.assets.dob
        }else if (name=='secoundarymark'){
          this.assets.secoundarymark = !this.assets.secoundarymark
        }else if (name=='puemark'){
          this.assets.puemark = !this.assets.puemark
        }else if (name=='consolitated'){
          this.assets.consolitated = !this.assets.consolitated
        }else if (name=='degree'){
          this.assets.degree = !this.assets.degree
        }else if (name=='kyc'){
          this.assets.kyc = !this.assets.kyc
        }else if (name=='community'){
          this.assets.community = !this.assets.community
        }else if (name=='supportDoc'){
          this.assets.supportDoc = !this.assets.supportDoc
        }
    }
  }
}
</script>
<!-- <div class="w-full overflow-hidden rounded-md border py-2">
    <div class="w-full flex flex-row justify-between items-center px-4 py-2 gap-3 border-b">
      <div class="flex gap-3 bg-red-100">
        <a href="" class="text-blue-500 text-start">d156-ft1266ty-tdd586b6-2645665</a>
        <p>Student Name</p> 
      </div>
      <button>View more</button>
    </div>
    <div class="flex flex-row px-4 py-2 gap-3 ">
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Payment</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">KYC Document</p>
        <input type="checkbox" checked> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Higher Educaton</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Degree Marksheet</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Degree Certificate</p>
        <input type="checkbox"> 
      </button>
    </div>  
  </div>
  <div class="bg-slate-300 rounded-md flex flex-col md:flex-row">
              <div class="w-1/2 h-full p-5">
                <div class="w-full h-full bg-white rounded-md p-3">
                  <img src="https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png" alt="Aadhar card">
                  <img src="https://cscdiksha.com/wp-content/uploads/2022/10/Aadhaar-pvc-Back1.png" alt="Aadhar card">
                </div>
              </div>
              <div class="w-1/2 h-full p-5">
                <table>
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Field value</th>
                      <th>Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="p-3">
                        <td class="w-1/3">
                          Name in Aadhaar
                        </td>
                        <td class="w-1/3">
                          My name
                        </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> correct
                      </td>
                    </tr>
                    <tr class="p-3">
                      <td class="w-1/3">
                        Name in Aadhaar
                      </td>
                      <td class="w-1/3">
                        My name
                      </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> Correct 
                      </td>
                    </tr>
                    <tr class="p-3">
                      <td class="w-1/3">
                        Name in Aadhaar
                      </td>
                      <td class="w-1/3">
                        My name
                      </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> Correct 
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  3/3
                </div>
                <div>
                  <button>Verified</button>
                </div>
              </div>
            </div> -->