<template>
  <div class="w-full bg-white min-h-screen mx-auto">
    <Navigation />
    <div class="max-w-7xl mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="Leads" />
        <div class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg shadow-lg">
              Sales Dashboard all lead view page
        </div>  
      </div>  
    </div>  
  </div>
</template>

<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/SalesLeftNavView.vue'
export default {
  name:'MyleadView',
  data(){
    return{
      isPopup : false,
      programs:[{
        id : 1,
        name : "MBA",
        electives : [{
            id:1,
            name:"Finance"
        },{
            id:2,
            name:"Marketing"
        },
        {
            id:3,
            name:"HRM"
        }]
    },
    {
      id : 2,
        name : "BBA",
        electives : [{
            id:1,
            name:"Finance BBA"
        },{
            id:2,
            name:"Marketing BBA"
        },
        {
            id:3,
            name:"HRM BBA"
        }]
    }],
    electives:[],
    leadList : []
    }
  },
  components: {
    Navigation,
    LeftNavigation,
  },
  created(){
    this.leadList = JSON.parse(localStorage.getItem("userLead"))
  },
  methods:{
    selectElective(id){
      this.programs.forEach(el=>{
        if(el.id == id){
          this.electives = el.electives
        }
      })
    },
    leadCapture(el){
      console.log(el.target)
      const short = require('short-uuid');
       let uuiTemp = short.generate();
      console.log(uuiTemp)
      let leadData = {
        leadId : uuiTemp,
        firstName : el.target.first_name.value,
        lastName : el.target.last_name.value,
        email : el.target.email_address.value,
        country : el.target.country.value,
        city : el.target.city.value,
        state : el.target.region.value,
        pincode : el.target.postal_code.value,
        program : el.target.program.value,
        elective : el.target.elective.value,
      }
      console.log(leadData)
      console.log(typeof(leadData))
      localStorage.setItem("userLead", JSON.stringify(leadData));
    }
  }
}
</script>

<style>

</style>