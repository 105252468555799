import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./index.css"
import axios from 'axios';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { 
    faCircleUser, 
    faBars, 
    faCaretDown, 
    faCaretRight, 
    faCaretLeft, 
    faAt, 
    faMobile, 
    faBook,
    faBookmark,
    faFlag,
    faChevronLeft,
    faChevronRight,
    } from '@fortawesome/free-solid-svg-icons'

axios.defaults.headers.common = {
    'Access-Key' : '01crftw!3j!pzl80=$i1(anfud#=_58ci+&qyzcgbg76zm+o0m'
};


/* add icons to the library */
library.add(
    faCircleUser, 
    faBars, 
    faCaretDown, 
    faCaretRight, 
    faCaretLeft, 
    faAt,
    faMobile,
    faBook,
    faBookmark,
    faFlag,
    faChevronLeft,
    faChevronRight,
    )

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router).mount('#app')
