<template>
  <div class="w-[220px]">
    <div class="flex flex-col h-[90vh] bg-[#01215c] text-white text-sm rounded-lg overflow-hidden">
      <router-link :to="{name:'OverView'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='OverView' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Overview
        </button>
      </router-link>
      <router-link :to="{name:'UnVerified'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='UnVerified' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Unverified
        </button>
      </router-link>
      <router-link :to="{name:'Verified'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='Verified' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Verified
        </button>
      </router-link>
      <router-link :to="{name:'Rejected'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='Rejected' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Rejected
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
props:["page"]
}
</script>

<style>

</style>