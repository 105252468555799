<template>
    <div class="hidden md:block xl:p-5">
        <ol class="progress-meter font-semibold">
          <li class="progress-point" :class="[this.completedStage=='0' && this.currentPage=='1' ? 'done' :this.completedStage>'0' && this.currentPage !='1' ? 'doneTrue' : 'todo']">Personal Information</li>
          <li class="progress-point" :class="[this.completedStage=='1' && this.currentPage=='2' ? 'done' :this.completedStage>'1' && this.currentPage !='2' ? 'doneTrue' : 'todo']">Fee Payment</li>
          <li class="progress-point" :class="[this.completedStage=='2' && this.currentPage=='3' ? 'done' :this.completedStage>'2' && this.currentPage !='3' ? 'doneTrue' : 'todo']">KYC Data</li>
          <li class="progress-point" :class="[this.completedStage=='3' && this.currentPage=='4' ? 'done' :this.completedStage>'3' && this.currentPage !='4' ? 'doneTrue' : 'todo']">Contact Information</li>
          <li class="progress-point" :class="[this.completedStage=='4' && this.currentPage=='5' ? 'done' :this.completedStage>'4' && this.currentPage !='5' ? 'doneTrue' : 'todo']">Academic Information</li>
          <li class="progress-point" :class="[this.completedStage=='5' && this.currentPage=='6' ? 'done' :this.completedStage>'5' && this.currentPage !='6' ? 'doneTrue' : 'todo']">Document Upload</li>
        </ol>
      </div>
</template>

<script>
export default {
  props:["completedStage","currentPage"],
created(){
},
}
</script>

<style src="@/assets/css/ProgressTracking.css">
</style>
