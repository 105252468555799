<template>
  <div class="w-full bg-white min-h-screen mx-auto">
    <Navigation />
    <div class="max-w-7xl mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="Enrolled" />
        <div class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg shadow-lg">
          Sales Dashboard enrolled lead view page
        </div>  
      </div>  
    </div>  
  </div>
</template>

<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/SalesLeftNavView.vue'
export default {
  name:'EnrolledleadView',
  data(){
    return{
      
    }
  },
  components: {
    Navigation,
    LeftNavigation
  },
  created(){
    this.leadList = JSON.parse(localStorage.getItem("userLead"))
  },
  methods:{

  }
}
</script>

<style>

</style>