<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img src="../../assets/Jain-Online-Logo.png" alt="" class="h-6 md:h-9" />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-xs md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-sm" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="3" currentPage="4" />
      <form @submit.prevent="communicationInfoPost">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <router-link
            :to="{ name: 'KycInfoForm' }"
            class="font-medium text-base"
          >
            <font-awesome-icon icon="fas fa-chevron-left" class="pr-3" />KYC
            Data
          </router-link>
          <p class="text-start text-xl font-medium text-teal-600">
            Contact Information
          </p>
          <router-link
            :to="{ name: 'EducationInfoForm' }"
            class="font-medium text-base"
          >
            Academic Information
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </router-link>
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
          </div>
          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p class="text-start text-xl font-light">
                  Country of residence
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="country" class="w-full text-base font-medium"
                    >Country <small class="text-red-500">*</small></label
                  >
                  <select
                    id="country"
                    name="country"
                    @change="stateCall"
                    autocomplete="country"
                    v-model="this.contact_info.country"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="country in this.country_list"
                      :key="country"
                      :value="country.name"
                      :selected_val="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-slate-300 hover:text-[#fcb21f]"
                  />
                  <div v-if="this.contact_info.country == 'India'">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">
                  Tell us More About Your Landmark
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="state" class="w-full text-base font-medium"
                    >State <small class="text-red-500">*</small></label
                  >
                  <select
                    @change="districtCall"
                    id="state"
                    name="state"
                    v-model="this.contact_info.state"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="state in this.state_list"
                      :key="state"
                      :value="state.name"
                      :selected_val="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start">
                  <label for="district" class="w-full text-base font-medium"
                    >District <small class="text-red-500">*</small></label
                  >
                  <select
                    @change="cityCall"
                    id="district"
                    name="district"
                    v-model="this.contact_info.district"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected value="">Select</option>
                    <option
                      v-for="district in this.district_list"
                      :key="district"
                      :value="district.name"
                      :selected_val="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start">
                  <label for="city" class="w-full text-base font-medium"
                    >City/Town <small class="text-red-500">*</small></label
                  >
                  <select
                    id="city"
                    name="city"
                    v-model="this.contact_info.city"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="city in this.city_list"
                      :key="city"
                      :value="city.name"
                      :selected_val="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-2 items-center mb-24">
                <p class="text-start text-xl font-light">
                  Address For Communication
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="address_line_1"
                    class="w-full text-base font-medium"
                    >Address Line 1 <small class="text-red-500">*</small></label
                  >
                  <input
                    type="text"
                    name="address_line_1"
                    v-model="this.contact_info.address_1"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  />
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-3">
                  <div>
                    <label
                      for="address_line_2"
                      class="w-full text-base font-medium"
                      >Address Line 2</label
                    >
                    <input
                      type="text"
                      name="address_line_2"
                      v-model="this.contact_info.address_2"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div>
                    <label for="pincode" class="w-full text-base font-medium"
                      >Pincode/Zipcode
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="pincode"
                      minlength="6"
                      maxlength="6"
                      v-model="this.contact_info.zipcode"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <div v-if="this.contact_info.country == 'India'">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-left"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-left"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(1)"
                    />
                  </div>
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFour"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Permanent Address</p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="is_permanenet"
                    class="w-full text-base font-medium"
                    >Is Permanent Address Same As Address For Communication?
                    <small class="text-red-500">*</small></label
                  >
                  <select
                    id="is_permanenet"
                    name="is_permanenet"
                    autocomplete="is_permanenet"
                    v-model="this.contact_info.permanent_address"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="Select">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(3)"
                  />
                  <div v-if="this.contact_info.permanent_address == 'No'">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(5)"
                    />
                  </div>
                  <div v-else>
                    <button
                      type="submit"
                      class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFive"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Permanent Address</p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="permanent_country"
                    class="w-full text-base font-medium"
                    >Country
                    <small class="text-red-500 text-base font-medium"
                      >*</small
                    ></label
                  >
                  <select
                    @change="stateCall"
                    name="permanent_country"
                    v-model="this.contact_info.p_country"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="country in this.country_list"
                      :key="country"
                      :value="country.name"
                      :selected_val="country.id"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="this.contact_info.p_country == 'India'"
                  class="w-full md:w-1/2 text-start flex flex-col gap-5"
                >
                  <div class="w-full text-start">
                    <label
                      for="permanent_state"
                      class="w-full text-base font-medium"
                      >State <small class="text-red-500">*</small></label
                    >
                    <select
                      @change="districtCall"
                      id="permanent_state"
                      name="permanent_state"
                      autocomplete="state"
                      v-model="this.contact_info.p_state"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="state in this.state_list"
                        :key="state"
                        :value="state.name"
                        :selected_val="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label
                      for="permanent_district"
                      class="w-full text-base font-medium"
                      >District <small class="text-red-500">*</small></label
                    >
                    <select
                      @change="cityCall"
                      id="permanent_district"
                      name="permanent_district"
                      autocomplete="district"
                      v-model="this.contact_info.p_district"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="district in this.district_list"
                        :key="district"
                        :value="district.name"
                        :selected_val="district.id"
                      >
                        {{ district.name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label
                      for="permanent_city"
                      class="w-full text-base font-medium"
                      >City/Town <small class="text-red-500">*</small></label
                    >
                    <select
                      id="permanent_city"
                      name="permanent_city"
                      autocomplete="city"
                      v-model="this.contact_info.p_city"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="city in this.city_list"
                        :key="city"
                        :value="city.name"
                        :selected_val="city.id"
                      >
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(6)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSix"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Permanent Address</p>
                <div class="w-full md:w-2/3 flex flex-col text-start gap-5">
                  <div class="w-full text-start">
                    <label
                      for="permanent_address_line_1"
                      class="w-full text-base font-medium"
                      >Address Line 1
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="permanent_address_line_1"
                      name="permanent_address_line_1"
                      v-model="this.contact_info.p_address_1"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label
                      for="permanent_address_line_2"
                      class="w-full text-base font-medium"
                      >Address Line 2</label
                    >
                    <input
                      type="text"
                      name="permanent_address_line_2"
                      v-model="this.contact_info.p_address_2"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label
                      for="permanent_pincode"
                      class="w-full text-base font-medium"
                      >Pincode/Zipcode
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="permanent_pincode"
                      name="permanent_pincode"
                      minlength="6"
                      maxlength="6"
                      v-model="this.contact_info.p_zipcode"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                  <button
                    type="submit"
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Save & Continue
                  </button>
                  <!-- <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" @click="selectStep(6)"/> -->
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-center rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-sm"
            >
              <div class="flex flex-col gap-3">
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-user-circle"
                      class="text-xl pr-2"
                    />
                    Name
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.fullName }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-at"
                      class="text-xl pr-2"
                    />Email
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.emailAddress }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-mobile"
                      class="text-xl pr-2"
                    />
                    Phone
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.CodePrimary }}
                    {{ this.formStudentInfo.mobilePrimary }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedProgram"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-book"
                      class="text-xl pr-2"
                    />
                    Program
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedProgram }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedElective"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-bookmark"
                      class="text-xl pr-2"
                    />
                    Elective
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedElective }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedNationality"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-flag"
                      class="text-xl pr-2"
                    />
                    Nationality
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedNationality }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row px-8 bg-pink-200">
         <div class="w-full 2xl:w-3/5 flex flex-row justify-between px-2">
           <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c]" />
           <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" />
         </div>
       </div> -->
        </div>
      </form>
      <!-- <img src="../../assets/background.png" alt="background" class="absolute inset-0"> -->
    </div>
  </div>
</template>

<script>
import Tracking from "@/components/TrackingView.vue";
import axios from "axios";
export default {
  name: "communicationForm",
  data() {
    return {
      find_country: "Select",
      is_permanent: "Select",
      is_permanent_country: "Select",
      is_already_done: false,
      country_list: [],
      state_list: [],
      district_list: [],
      city_list: [],

      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,
      isStepSix: false,

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
      },
      contact_info: {
        country: "",
        state: "",
        district: "",
        city: "",
        address_1: "",
        address_2: "",
        zipcode: "",
        permanent_address: "No",
        p_country: "",
        p_state: "",
        p_district: "",
        p_city: "",
        p_address_1: "",
        p_address_2: "",
        p_zipcode: "",
      },
    };
  },
  components: {
    Tracking,
  },

  created() {
    this.countryCall();

    let Tracking = JSON.parse(localStorage.getItem("Tracking"));
    if (Tracking["contact"] == true) {
      this.is_already_done = true;
    }
  },

  methods: {
    cityCall(el) {
      let selectdistrict =
        el.target.selectedOptions[0].attributes.selected_val.value;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/city?district_id=${selectdistrict}`
        )
        .then((resp) => {
          this.city_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    districtCall(el) {
      let selectstate =
        el.target.selectedOptions[0].attributes.selected_val.value;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/district?state_id=${selectstate}`
        )
        .then((resp) => {
          this.district_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stateCall(el) {
      let selectCountry =
        el.target.selectedOptions[0].attributes.selected_val.value;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/state?country_id=${selectCountry}`
        )
        .then((resp) => {
          this.state_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countryCall() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/country`
        )
        .then((resp) => {
          this.country_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    communicationInfoPost() {
      console.log("text");
      let contactstatus = {
        contact_formdata: this.contact_info,
        submitTime: new Date(),
      };

      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));

      getStudentVal["formsInfo"]["contactInfo"] = contactstatus;

      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));

      Tracking["contact"] = true;

      localStorage.setItem("Tracking", JSON.stringify(Tracking));

      this.$router.push({ name: "EducationInfoForm" });
    },
    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
      } else if (step == 6) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = true;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>

<style src="@/assets/css/ProgressTracking.css"></style>
