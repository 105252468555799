<template>
  <div class="w-full bg-white min-h-screen mx-auto relative">
    <Navigation />
    <div class="w-full mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="UnVerified" />
        <div class="w-full flex flex-col gap-8 mx-8 overflow-auto h-[90vh] border border-gray-300 rounded-lg p-6">
          <div class="flex flex-col gap-2 text-start">
            <p class="text-2xl">
              Uploaded Documents (2/8)
            </p>
            <div class="w-full flex flex-row justify-between px-4 py-2 font-bold text-xl">
              <div>
                Document Name
              </div>
              <div class="flex gap-2 cursor-pointer">
                  <p v-if="activeTap !='tap1'" @click="previousTap"> Previous</p>  
                  <p v-if="activeTap !='tap8'" @click="nextTap">Next</p>
              </div>
            </div>
            <div class="flex flex-row 2xl:justify-center text-base bg-slate-100 overflow-x-auto cursor-pointer">
              <div v-if="this.documents.passport.file != ''" @click="selectFile('passport')" class="border border-gray-300 px-2 py-1 rounded-l whitespace-nowrap" 
                  :class="this.activePassportTap ? 'bg-[#01215c] text-white ' : '' "> 
                <b>Passport size photo {{ this.activePassportTap }}</b> 
              </div>
              <div v-if="this.documents.dob.file != ''" @click="selectFile('dob')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activeDobTap ? 'bg-[#01215c] text-white ' : '' "> 
                <b>Date of birth proof</b> 
              </div>
              <div v-if="this.documents.kyc.file != ''" @click="selectFile('kyc')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activeKycTap ? 'bg-[#01215c] text-white ' : '' "> 
                <b>KYC Document</b> 
              </div>
              <div v-if="this.documents.community.file != ''" @click="selectFile('community')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activeCommunityTap ? 'bg-[#01215c] text-white ' : '' "> 
                <b>Community Certificate</b> 
              </div>
              <div v-if="this.documents.secoundarymark.file != ''" @click="selectFile('secoundarymark')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activeSecoundarymarkTap ? 'bg-[#01215c] text-white ' : '' ">
                <b>10 th Marksheet</b> 
              </div>
              <div v-if="this.documents.puemark.file != ''" @click="selectFile('puemark')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activePuemarkTap ? 'bg-[#01215c] text-white ' : '' ">
                <b>12 th Marksheet</b> 
              </div>
              <div v-if="this.documents.consolitated.file != ''" @click="selectFile('consolitated')" class="border border-gray-300 px-2 py-1 whitespace-nowrap" 
                  :class="this.activeConsolitatedTap ? 'bg-[#01215c] text-white ' : '' ">
                <b>Ug Marksheet</b> 
              </div>
              <div v-if="this.documents.supportDoc.file != ''" @click="selectFile('supportDoc')" class="border border-gray-300 px-2 py-1 rounded-r whitespace-nowrap" 
                  :class="this.activeSupportDocTap ? 'bg-[#01215c] text-white ' : '' ">
                <b>Other Supporting Document</b> 
              </div>
            </div>
          </div>
          <div v-if="this.activePassportTap" class="flex flex-col text-start">
              Passport size photo
            <div class="flex flex-col md:flex-row gap-2 text-xs">
              <div class="w-2/3 border border-gray-300 rounded-md overflow-hidden flex flex-row justify-center items-center">
                <img :src="this.documents.passport.file" alt="">
              </div>
              <div class="w-1/3 flex flex-col justify-start items-start text-start gap-2 border border-gray-300 px-6 py-4 rounded-md">
                <label for="passportSizeComment" class="text-sm font-medium">Comment</label>  
                <textarea name="passport_size_comment" id="passportSizeComment" cols="30" rows="10" class="w-full px-2 py-1 border border-gray-300 rounded-md"></textarea>
                <div class="w-64 flex flex-row px-2 py-2 justify-between bg-slate-200">
                  Image Quality <select name="image_quality" id="imageQuality" v-model="secondaryMarkSheetQuality"
                    class="px-4 rounded-md bg-white">
                    <option value="Bad">Bad</option>
                    <option value="Good">Good</option>
                    <option value="Better">Better</option>
                  </select>
                  <label for="dob_proof" class="px-2 py-1">
                    <input id="dob_proof" type="checkbox" class="">
                  </label>
                </div>
                <div>
                  6/6
                </div>
                <div class="w-full flex justify-around">
                  <button class="px-3 py-2 rounded border border-red-500 bg-red-100 text-sm">Ask revision</button>
                  <button class="px-3 py-2 rounded border border-green-500 text-sm">Approve</button>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="this.activeDobTap" class="flex flex-col text-start">
            Date of birth proof
            <div class="flex flex-col md:flex-row gap-4 text-xs">
              <div class="w-2/3 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.dob.file" alt="">
              </div>
              <div class="w-1/3 flex flex-col items-start">
                <div class="flex flex-row py-2 gap-2">
                  Date of birth as per Application (yyyy-mm-dd): <b>{{ this.basicInfo.dob }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="dob_proof" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="dob_proof" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.activeKycTap" class="flex flex-col text-start">
            KYC Document
            <div class="flex flex-col md:flex-row text-xs">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.kyc.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start p-4">
                <div class="flex flex-row py-2 gap-2">
                  Nationality as per Application: <b>{{ this.basicInfo.nationality }}</b> 
                </div>
                <div class="flex flex-row py-2 gap-2">
                  Date of birth as per Application (yyyy-mm-dd): <b>{{ this.basicInfo.dob }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="kyc_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="kyc_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
                <div v-if="this.basicInfo.nationality== 'Indian'" class="w-full">
                  <div class="flex flex-row py-2 gap-2">
                    Aadhar Number as per Application: <b>{{ this.basicInfo.aadhar_number }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                  <div class="flex flex-row py-2 gap-2">
                    Address as per Application: <b>{{ this.contacInfo.address_line_1 }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                </div>
                <div v-else class="w-full">
                  <div class="flex flex-row py-2 gap-2">
                    KYC type as per Application: <b>{{ this.basicInfo.kyc_name }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                  <div class="flex flex-row py-2 gap-2">
                    KYC Number as per Application: <b>{{ this.basicInfo.kyc_number }}</b> 
                  </div>
                  <div class="w-full flex flex-row py-2 justify-between">
                    <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                    <label for="kyc_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                      <input id="kyc_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="this.activeCommunityTap" class="flex flex-col text-start">
            Community Certificate
            <div class="flex flex-col md:flex-row text-xs gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.community.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start">
                <div class="flex flex-row py-2 gap-2">
                  Category as per Application: <b>{{ this.basicInfo.category }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="dob_proof" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="dob_proof" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.activeSecoundarymarkTap" class="flex flex-col text-start border border-gray-300 p-5 rounded-md">
            <span class="py-2">10th Marksheet</span>
            <div class="flex flex-col md:flex-row gap-4 text-xs">
              <div class="w-2/3 border border-gray-300 rounded-md overflow-hidden p-3 bg-slate-100">
                <img :src="this.documents.secoundarymark.file" alt="">
              </div>
              <div class="w-1/3 flex flex-col items-start border border-gray-300 rounded-md p-3">
                <div class="flex flex-row py-2 gap-2 text-start">
                  School Name as per application: 
                  <b><br>{{ this.educationInfo.SecondarySchool }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Board Name as per application: <b>{{ this.educationInfo.SecondarySchoolBoard }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Year of Passing as per application: <b>{{ this.educationInfo.Secondaryyop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per application: <b>{{ this.educationInfo.Secondaryscheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  GPGA as per application: <b>{{ this.educationInfo.SecondaryCgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between border-b">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="secoundarymark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Image quality
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <select name="image_quality" id="imageQuality" v-model="secondaryMarkSheetQuality"
                    class="px-4 rounded-md bg-slate-200">
                    <option value="Bad">Bad</option>
                    <option value="Good">Good</option>
                    <option value="Better">Better</option>
                  </select>
                  <label v-if="secondaryMarkSheetQuality=='Bad'" for="secoundarymark_proof_5" 
                    class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as rejected
                  </label>
                  <label v-else for="secoundarymark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="secoundarymark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

              </div>
            </div>
          </div>

          <div v-if="this.activePuemarkTap" class="flex flex-col text-start">
            12 th Marksheet
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.puemark.file" alt="">
              </div>

              <div class="w-1/2 flex flex-col items-start text-xs">
                <div class="flex flex-row py-2 gap-2">
                  School Name as per Application: <b>{{ this.educationInfo.SecondarySchool }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Board Name as per Application: <b>{{ this.educationInfo.SecondarySchoolBoard }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Year of Passing as per Application: <b>{{ this.educationInfo.Secondaryyop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.Secondaryscheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  GPGA as per Application: <b>{{ this.educationInfo.SecondaryCgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.activeDegreeTap" class="flex flex-col text-start">
            Ug Marksheet
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.degree.file" alt="">
              </div>
              <div class="w-1/2 flex flex-col items-start text-xs">
                <div class="flex flex-row py-2 gap-2">
                  College Name as per Application: <b>{{ this.educationInfo.college_name }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_1" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_1" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  University Name as per Application: <b>{{ this.educationInfo.university_name }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_2" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_2" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Degree as per Application: <b>{{ this.educationInfo.ug_degree }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_3" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_3" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.ug_yop }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  Marking Scheme as per Application: <b>{{ this.educationInfo.ugmark_scheme }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_4" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_4" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>

                <div class="flex flex-row py-2 gap-2">
                  CGPA as per Application: <b>{{ this.educationInfo.ug_cgpa }}</b> 
                </div>
                <div class="w-full flex flex-row py-2 justify-between">
                  <span class="px-2 py-1 border border-gray-300 rounded-md">Update</span> 
                  <label for="puemark_proof_5" class="px-2 py-1 border border-gray-300 rounded-md"> 
                    <input id="puemark_proof_5" type="checkbox" class="mr-2"> Mark as Verified
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="this.activeSupportDocTap" class="flex flex-col text-start">
            Other Supporting Document
            <div class="flex flex-col md:flex-row gap-4">
              <div class="w-1/2 border border-gray-300 rounded-md overflow-hidden">
                <img :src="this.documents.supportDoc.file" alt="">
              </div>
              <div class="w-1/2">

              </div>
            </div>
          </div>
        </div>  
      </div>  
    </div>
    <!-- <div class="w-5/6 absolute bg-red-200 h-[90vh] top-0 mx-auto mt-20">

    </div>   -->
  </div>
</template>

<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/DocumentLeftNavView.vue'
export default {
  name: 'DocumentUnVerifiedView',
  components: {
    Navigation,
    LeftNavigation,
  },
  data(){
    return{
      // For uploaded document quality check
      secondaryMarkSheetQuality: 'Good',

      // For getting student date
      studentInfo: {},
      documents : [],
      documents_list : [],
      basicInfo: {},
      contacInfo: {},
      educationInfo: {},

      // For control the document detail view
      assets : {
        passport: false,
        dob: false,
        secoundarymark: true,
        puemark: false,
        consolitated: false,
        degree: false,
        kyc: false,
        community: false,
        supportDo : false,
    },
      activeTap : "tap1",
      activePassportTap: true,
      activeDobTap: false,
      activeSecoundarymarkTap: false,
      activePuemarkTap: false,
      activeConsolitatedTap: false,
      activeDegreeTap: false,
      activeKycTap: false,
      activeCommunityTap: false,
      activeSupportDocTap: false,
    }
  },
  created(){
    let Tracking = JSON.parse(localStorage.getItem('Tracking'))
    if(Tracking['document']==true){
      this.studentInfo = JSON.parse(localStorage.getItem('studentInfo'))
      this.basicInfo = this.studentInfo.formsInfo.basicInfo.basicInfo_formdata
      console.log(this.studentInfo)
      this.contacInfo = this.studentInfo.formsInfo.contactInfo.contact_formdata
      this.educationInfo = this.studentInfo.formsInfo.educationInfo.education_formdata
      this.documents = this.studentInfo.formsInfo.Documents.document_formdata
    }
  },
  methods:{
    nextTap(){
    this.activeTap
      let active_tap_Val= this.activeTap.slice(-1)
      let next_tap = 'tap'+(parseInt(active_tap_Val)+1)
      this.activeTap = next_tap
      console.log(this.activeTap)
    },
    previousTap(){
    this.activeTap
      let active_tap_Val= this.activeTap.slice(-1)
      let next_tap = 'tap'+(parseInt(active_tap_Val)-1)
      this.activeTap = next_tap
      console.log(this.activeTap)
    },
    selectFile(name){
      console.log('test')
      if(name=='passport'){
        this.activePassportTap='tap1'
          this.activePassportTap=true
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }
        else if (name=='dob'){
          this.activeTap='tap2'
          this.activePassportTap=false
          this.activeDobTap = true
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }
        else if (name=='secoundarymark'){
          this.activeSecoundarymarkTap='tap5'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = true
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }else if (name=='puemark'){
          this.activePuemarkTap='tap6'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = true
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }else if (name=='consolitated'){
          this.activeConsolitatedTap='tap7'

          this.assets.consolitated = !this.assets.consolitated
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = true
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }else if (name=='degree'){
          this.activeDegreeTap='tap9'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = true
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }else if (name=='kyc'){
          this.activeKycTap='tap3'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = true
          this.activeCommunityTap = false
          this.activeSupportDocTap = false
        }else if (name=='community'){
          this.activeCommunityTap='tap4'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = true
          this.activeSupportDocTap = false
        }else if (name=='supportDoc'){
          this.activeSupportDocTap='tap8'
          this.activePassportTap=false
          this.activeDobTap = false
          this.activeSecoundarymarkTap = false
          this.activePuemarkTap = false
          this.activeConsolitatedTap = false
          this.activeDegreeTap = false
          this.activeKycTap = false
          this.activeCommunityTap = false
          this.activeSupportDocTap = true
        }
    }
  }
}
</script>