<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img src="../../assets/Jain-Online-Logo.png" alt="" class="h-6 md:h-9" />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-xs md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-sm" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="2" currentPage="3" />
      <form @submit.prevent="KycformPost">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <router-link
            :to="{ name: 'FeeInfoForm' }"
            class="font-medium text-base"
          >
            <font-awesome-icon icon="fas fa-chevron-left" class="pr-3" />Fee
            Payment
          </router-link>
          <p class="text-start text-xl font-medium text-teal-600">KYC Data</p>
          <router-link
            :to="{ name: 'communicationForm' }"
            class="font-medium text-base"
          >
            Contact Information
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </router-link>
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
          </div>
          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p
                  v-if="this.studentBasicInfo.selectedNationality == 'Indian'"
                  class="text-start text-xl font-light"
                >
                  Category
                </p>
                <p v-else class="text-start text-xl font-light">KYC Info</p>
                <div
                  v-if="this.studentBasicInfo.selectedNationality == 'Indian'"
                  class="w-full md:w-1/2 text-start"
                >
                  <label for="category" class="w-full font-medium"
                    >Category <small class="text-red-500">*</small></label
                  >
                  <select
                    :required="
                      this.studentBasicInfo.selectedNationality == 'Indian'
                        ? true
                        : false
                    "
                    id="category"
                    name="category"
                    autocomplete="category"
                    class="text-base mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select Category</option>
                    <option
                      v-for="category in this.category_list"
                      :key="category"
                      :value="category.name"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="this.studentBasicInfo.selectedNationality != 'Indian'"
                  class="w-full md:w-1/2 text-start"
                >
                  <label for="kycName" class="w-full text-base font-medium"
                    >KYC name <small class="text-red-500">*</small></label
                  >
                  <select
                    :required="
                      this.studentBasicInfo.selectedNationality != 'Indian'
                        ? true
                        : false
                    "
                    id="kycName"
                    name="kycName"
                    autocomplete="kycName"
                    v-model="kyc_info.kyc_name"
                    class="text-base mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="kyc in this.kyc_type_list"
                      :key="kyc"
                      :value="kyc[1]"
                    >
                      {{ kyc[1] }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="this.studentBasicInfo.selectedNationality != 'Indian'"
                  class="w-full md:w-1/2 text-start"
                >
                  <label for="kycNumber" class="w-full font-medium"
                    >KYC Number <small class="text-red-500">*</small></label
                  >
                  <input
                    :required="
                      this.studentBasicInfo.selectedNationality != 'Indian'
                        ? true
                        : false
                    "
                    type="text"
                    id="kycNumber"
                    minlength="8"
                    maxlength="20"
                    v-model="kyc_info.kyc_number"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl text-xs md:text-sm">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-5xl md:text-7xl text-gray-300"
                    @click="selectStep(1)"
                  />
                  <div
                    v-if="this.studentBasicInfo.selectedNationality == 'Indian'"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(4)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Aadhar Information</p>

                <div class="w-full md:w-1/2 text-start">
                  <label for="aadharNumber" class="w-full font-medium"
                    >Aadhar Number<small class="text-red-500">*</small></label
                  >
                  <div class="mt-2 w-full flex flex-row items-center">
                    <input
                      type="text"
                      minlength="4"
                      maxlength="4"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.aadharPartOne"
                      class="w-1/3 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                    <span class="text-xl px-1 font-bold">-</span>
                    <input
                      type="text"
                      minlength="4"
                      maxlength="4"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.aadharPartTwo"
                      class="w-1/3 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                    <span class="text-xl px-1 font-bold">-</span>
                    <input
                      type="text"
                      minlength="4"
                      maxlength="4"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.aadharPartThree"
                      class="w-1/3 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2 text-start">
                  <label for="nameInAadharCard" class="w-full font-medium"
                    >Name in Aadhar Card
                    <small class="text-red-500">*</small></label
                  >
                  <input
                    :required="
                      this.studentBasicInfo.selectedNationality == 'Indian'
                        ? true
                        : false
                    "
                    type="text"
                    id="nameInAadharCard"
                    name="name_in_aadhar_card"
                    v-model="kyc_info.aadhar_name"
                    class="text-sm mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <!-- <p class="text-start text-xl font-light">Academic Bank Credit</p> -->
                <div class="w-full md:w-1/2 text-start">
                  <label for="abcId" class="w-full font-medium"
                    >ABC id <small class="text-red-500">*</small></label
                  >
                  <div class="mt-2 w-full flex flex-row items-center">
                    <input
                      type="text"
                      minlength="3"
                      maxlength="3"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.abcPartOne"
                      class="w-1/4 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                    <span class="text-xl px-1 font-bold">-</span>
                    <input
                      type="text"
                      minlength="3"
                      maxlength="3"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.abcPartTwo"
                      class="w-1/4 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                    <span class="text-xl px-1 font-bold">-</span>
                    <input
                      type="text"
                      minlength="3"
                      maxlength="3"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.abcPartThree"
                      class="w-1/4 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                    <span class="text-xl px-1 font-bold">-</span>
                    <input
                      type="text"
                      minlength="3"
                      maxlength="3"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      v-model="this.abcPartFour"
                      class="w-1/4 text-sm block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-center"
                      style="letter-spacing: 0.25em"
                    />
                  </div>
                  <!-- <input :required="this.studentBasicInfo.selectedNationality=='Indian' ? true : false" type="text" id="abcId" name="abc_id" v-model="kyc_info.abc_id" minlength="12" maxlength="12"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"> -->
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-10">
                  <div>
                    <label for="kycExpected" class="w-full font-medium"
                      >KYC expected date
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      :required="
                        this.studentBasicInfo.selectedNationality == 'Indian' &&
                        !this.kyc_info.abc_id
                          ? true
                          : false
                      "
                      type="date"
                      v-model="kyc_info.kyc_expected"
                      id="kycExpected"
                      name="kyc_expected"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  v-if="this.studentBasicInfo.selectedNationality == 'Indian'"
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl"
                >
                  <small class="py-2 text-start"
                    >If you don't have a <b>ABC id</b>,
                    <a
                      href="https://www.abc.gov.in/"
                      target="_blank"
                      class="font-bold text-sky-600"
                      >Click here</a
                    >
                    to Apply. Save the form before leave the page.
                  </small>
                  <small
                    >Without ABC ID, you will not be allowed in
                    examinations</small
                  >
                </div>
                <div class="w-full flex flex-row justify-between mt-1">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(2)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFour"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Language & Status</p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="bloodGroup" class="w-full font-medium"
                    >Blood Group <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="bloodGroup"
                    name="bloodGroup"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="bloodgroup in this.bloodgroup_list"
                      :key="bloodgroup"
                      :value="bloodgroup.name"
                    >
                      {{ bloodgroup.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start">
                  <label for="motherTongue" class="w-full font-medium"
                    >Mother Tongue</label
                  >
                  <select
                    id="motherTongue"
                    name="mother_tongue"
                    v-model="kyc_info.mother_tongue"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="motherTongue in this.motherTongue_list"
                      :key="motherTongue"
                      :value="motherTongue.name"
                    >
                      {{ motherTongue.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <div>
                    <label for="maritalStatus" class="w-full font-medium"
                      >Marital Status</label
                    >
                    <select
                      id="maritalStatus"
                      name="marital_status"
                      v-model="kyc_info.marital_status"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="maritalstatus in this.marital_status_list"
                        :key="maritalstatus"
                        :value="maritalstatus.name"
                      >
                        {{ maritalstatus.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <div
                    v-if="this.studentBasicInfo.selectedNationality == 'Indian'"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-caret-left"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                  <div v-else>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-left"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(1)"
                    />
                  </div>
                  <!-- <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c] hover:text-[#fcb21f]" @click="selectStep(3)"/> -->
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFive"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-xl font-light">Parents Detail</p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="fatherName" class="w-full font-medium"
                    >Father's Name <small class="text-red-500">*</small></label
                  >
                  <input
                    required
                    type="text"
                    id="fatherName"
                    name="father_name"
                    minlength="4"
                    maxlength="30"
                    v-model="kyc_info.father_name"
                    class="capitalize mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  />
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <div>
                    <label for="motherName" class="w-full font-medium"
                      >Mother's Name
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      required
                      type="text"
                      id="motherName"
                      name="mother_name"
                      v-model="kyc_info.mother_name"
                      class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                  <button
                    type="submit"
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Save & Continue
                  </button>
                  <!-- <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" @click="selectStep(6)"/> -->
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-center rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-sm"
            >
              <div class="flex flex-col gap-3">
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-user-circle"
                      class="text-xl pr-2"
                    />
                    Name
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.fullName }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-at"
                      class="text-xl pr-2"
                    />Email
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.emailAddress }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-mobile"
                      class="text-xl pr-2"
                    />
                    Phone
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.CodePrimary }}
                    {{ this.formStudentInfo.mobilePrimary }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedProgram"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-book"
                      class="text-xl pr-2"
                    />
                    Program
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedProgram }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedElective"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-bookmark"
                      class="text-xl pr-2"
                    />
                    Elective
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedElective }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedNationality"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-flag"
                      class="text-xl pr-2"
                    />
                    Nationality
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedNationality }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import Navigation from '@/components/NavigationView.vue'
import Tracking from "@/components/TrackingView.vue";
// import LeftNavigation from '@/components/FormLeftNavView.vue'
import axios from "axios";
export default {
  name: "KycInfoForm",
  data() {
    return {
      category_list: [],
      studentBasicInfo: {},
      bloodgroup_list: [],
      motherTongue_list: [],
      marital_status_list: [],
      kyc_type_list: [],

      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
      },

      aadharPartOne: "",
      aadharPartTwo: "",
      aadharPartThree: "",

      abcPartOne: "",
      abcPartTwo: "",
      abcPartThree: "",
      abcPartFour: "",

      kyc_info: {
        category: "",
        kyc_name: "",
        kyc_number: "",
        aadhar_number: "",
        aadhar_name: "",
        abc_id: "",
        kyc_expected: "",
        mother_tongue: "",
        marital_status: "",
        father_name: "",
        mother_name: "",
      },
    };
  },
  components: {
    Tracking,
  },

  created() {
    this.categoryCall();
    this.bloodGroupCall();
    this.motherTongueCall();
    this.maritalStatusCall();
    this.kycType();

    let Tracking = JSON.parse(localStorage.getItem("Tracking"));
    if (Tracking["basic"] == true) {
      this.studentBasicInfo = JSON.parse(
        localStorage.getItem("studentInfo")
      ).formsInfo.basicInfo.basicInfo_formdata;
      console.log(this.studentBasicInfo, "info");
    }
    // this.studentInfo = JSON.parse(localStorage.getItem('studentInfo')).
  },
  mounted() {},
  methods: {
    kycType() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/kyc-doc-types`
        )
        .then((resp) => {
          this.kyc_type_list = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    maritalStatusCall() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/marital-status`)
        .then((resp) => {
          this.marital_status_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    motherTongueCall() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/mother-tongue`)
        .then((resp) => {
          this.motherTongue_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    bloodGroupCall() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/blood-group`)
        .then((resp) => {
          this.bloodgroup_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    categoryCall() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/caste-category`)
        .then((resp) => {
          this.category_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    KycformPost() {
      this.kyc_info.aadhar_number =
        this.aadharPartOne + this.aadharPartTwo + this.aadharPartThree;
      this.kyc_info.abc_id =
        this.abcPartOne +
        this.abcPartTwo +
        this.abcPartThree +
        this.abcPartFour;

      let kycstatus = {
        kyc_formdata: this.kyc_info,
        is_kyc: true,
      };

      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));

      getStudentVal["formsInfo"]["kycInfo"] = kycstatus;
      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));

      Tracking["kyc"] = true;
      this.$router.push({ name: "communicationForm" });
    },

    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>
