import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

// import for Student dashboard
import StudentDashboardView from '../views/StudentDashboard/StudentFormView'
// import BasicInfoFormView from '../views/StudentDashboard/BasicInfoFormView'
// import FeeInfoFormView from '../views/StudentDashboard/FeeInfoFormView'
// import KycInfoFormView from '../views/StudentDashboard/KycInfoFormView'
// import communicationFormView from '../views/StudentDashboard/CommunicationFormView'
// import EducationInfoFormView from '../views/StudentDashboard/EducationFormView'
// import DocumentUploadView from '../views/StudentDashboard/DocumentUploadView'
import BasicInfoFormView from '../views/StudentDashboard/BasicInfoFormViewNew'
import FeeInfoFormView from '../views/StudentDashboard/FeeInfoFormViewNew'
import KycInfoFormView from '../views/StudentDashboard/KycInfoFormViewNew'
import communicationFormView from '../views/StudentDashboard/CommunicationFormViewNew'
import EducationInfoFormView from '../views/StudentDashboard/EducationFormViewNew'
import DocumentUploadView from '../views/StudentDashboard/DocumentUploadViewNew'

// import for Document dashboard
import DocumentDashboardView from '../views/VerficationDashboard/DocumentDashboardView'
import DocumentUnVerifiedView from '../views/VerficationDashboard/DocumentUnVerifiedView'
import DocumentVerifiedView from '../views/VerficationDashboard/DocumentVerifiedView'
import DocumentRejectedView from '../views/VerficationDashboard/DocumentRejectedView'
import DocumentDetailView from '../views/VerficationDashboard/DocumentDetailView'

// import for Sales dashboard
import LeadDashboardView from '../views/LeadDashboard/LeadDashboardView'
import LeadDashboardLeadView from '../views/LeadDashboard/LeadDashboardLeadView'
import LeadDashboardEnrolledView from '../views/LeadDashboard/LeadDashboardEnrolledView'
import LeadDashboardRejectedView from '../views/LeadDashboard/LeadDashboardRejectedView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  
  {
    path: '/application/',
    name: 'application',
    component: StudentDashboardView
  },
  {
    path: '/appilcation/basic-info/:id?',
    name: 'BasicInfoForm',
    component: BasicInfoFormView,
    props: router => ({
      id: router.params.id,
  }),
  },
  {
    path: '/appilcation/fee-info/',
    name: 'FeeInfoForm',
    component: FeeInfoFormView
  },
  {
    path: '/appilcation/kyc-info/',
    name: 'KycInfoForm',
    component: KycInfoFormView
  },
  {
    path: '/appilcation/academic-info/',
    name: 'EducationInfoForm',
    component: EducationInfoFormView
  },
  {
    path: '/appilcation/contact-info/',
    name: 'communicationForm',
    component: communicationFormView
  },
  {
    path: '/appilcation/document-upload/',
    name: 'DocumentUploadForm',
    component: DocumentUploadView
  },

  // For Document verification dashboard
  {
    path: '/document-verification/overview/',
    name: 'OverView',
    component: DocumentDashboardView
  },
  {
    path: '/document-verification/unverified/',
    name: 'UnVerified',
    component: DocumentUnVerifiedView
  },
  {
    path: '/document-verification/verified/',
    name: 'Verified',
    component: DocumentVerifiedView
  },
  {
    path: '/document-verification/rejected/',
    name: 'Rejected',
    component: DocumentRejectedView
  },

  {
    path: '/document-verification/document-view/:id',
    name: 'DocumentDetailView',
    component: DocumentDetailView,
    props: router => ({
      id: router.params.id,
  }),

  },
  // For Document verification dashboard
  {
    path: '/mylead/overview/',
    name: 'MyLead',
    component: LeadDashboardView
  },
  {
    path: '/mylead/leads/',
    name: 'Leads',
    component: LeadDashboardLeadView
  },
  {
    path: '/mylead/enrolled-leads/',
    name: 'Enrolled',
    component: LeadDashboardEnrolledView
  },
  {
    path: '/mylead/rejected-leads/',
    name: 'RejectedLeads',
    component: LeadDashboardRejectedView
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
