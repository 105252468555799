<template>
  <div class="w-full max-w-7xl bg-gray-50 min-h-screen mx-auto">
    <Navigation />
    <div class="py-4 text-base text-slate-600">
      This is Mock up Page design.<br>
      You can Navigate Between Dashboards Using Button on the TOP.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navigation from '@/components/NavigationView.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Navigation
  }
}
</script>
