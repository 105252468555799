<template>
  <div class="w-full bg-white min-h-screen mx-auto">
    <Navigation />
    <div class="max-w-7xl mx-auto p-2">
      <div class="flex flex-row h-full">
        <LeftNavigation page="Verified" />
        <div class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg shadow-lg">
          Document Dashboard Document Verified Lead page
        </div>  
      </div>  
    </div>  
  </div>
</template>
<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/DocumentLeftNavView.vue'
export default {
  name: 'DocumentVerifiedView',
  components: {
  Navigation,
  LeftNavigation,
  }
}
</script>

<!-- <div class="w-full overflow-hidden rounded-md border py-2">
    <div class="w-full flex flex-row justify-between items-center px-4 py-2 gap-3 border-b">
      <div class="flex gap-3 bg-red-100">
        <a href="" class="text-blue-500 text-start">d156-ft1266ty-tdd586b6-2645665</a>
        <p>Student Name</p> 
      </div>
      <button>View more</button>
    </div>
    <div class="flex flex-row px-4 py-2 gap-3 ">
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Payment</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">KYC Document</p>
        <input type="checkbox" checked> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Higher Educaton</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Degree Marksheet</p>
        <input type="checkbox"> 
      </button>
      <button class="flex flex-row justify-between px-4 py-1.5 border border-slate-300 rounded shadow">
        <p class="pr-4">Degree Certificate</p>
        <input type="checkbox"> 
      </button>
    </div>  
  </div>
  <div class="bg-slate-300 rounded-md flex flex-col md:flex-row">
              <div class="w-1/2 h-full p-5">
                <div class="w-full h-full bg-white rounded-md p-3">
                  <img src="https://cdn.pixabay.com/photo/2022/11/09/00/44/aadhaar-card-7579588_1280.png" alt="Aadhar card">
                  <img src="https://cscdiksha.com/wp-content/uploads/2022/10/Aadhaar-pvc-Back1.png" alt="Aadhar card">
                </div>
              </div>
              <div class="w-1/2 h-full p-5">
                <table>
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Field value</th>
                      <th>Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="p-3">
                        <td class="w-1/3">
                          Name in Aadhaar
                        </td>
                        <td class="w-1/3">
                          My name
                        </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> correct
                      </td>
                    </tr>
                    <tr class="p-3">
                      <td class="w-1/3">
                        Name in Aadhaar
                      </td>
                      <td class="w-1/3">
                        My name
                      </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> Correct 
                      </td>
                    </tr>
                    <tr class="p-3">
                      <td class="w-1/3">
                        Name in Aadhaar
                      </td>
                      <td class="w-1/3">
                        My name
                      </td>
                      <td class="w-1/3">
                        <input type="Checkbox"> Correct 
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  3/3
                </div>
                <div>
                  <button>Verified</button>
                </div>
              </div>
            </div> -->