<template>
   <div class="w-full min-h-screen mx-auto bg-white">
    <Navigation />
    <div class="w-full 2xl:max-w-7xl mx-auto h-full">
      <!-- Student Application View -->
      <!-- tracking -->
      <div class="flex flex-row h-full">
        <LeftNavigation page="application" />
        <div class="w-full flex flex-col mx-auto overflow-auto h-[90vh] shadow bg-blue-50">
          <Tracking stageLevel="home" />
          Overall info about the application, email & sms trigger info will be added here
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/NavigationView.vue'
import Tracking from '@/components/TrackingView.vue'
import LeftNavigation from '@/components/FormLeftNavView.vue'
export default {
  name: 'StudentView',
  data(){
    return{
    }
  },
  components: {
    Navigation,
    Tracking,
    LeftNavigation,
  }
}
</script>

<style scoped>
</style>
