<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img src="../../assets/Jain-Online-Logo.png" alt="" class="h-6 md:h-9" />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-base md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-base" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="0" currentPage="1" />
      <form @submit.prevent="basicInfoPost">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <p class="text-start text-xl font-semibold text-teal-600">
            Personal Information
          </p>
          <router-link
            :to="{ name: 'FeeInfoForm' }"
            class="font-medium text-base"
          >
            Fee Payment
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </router-link>
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
          </div>

          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 md:gap-10 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p class="text-start text-xl md:text-3xl font-light">
                  Select Your Program
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="program"
                    class="block text-gray-700 font-medium text-base"
                    >Select Program <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="program"
                    @change="selectElective"
                    name="programName"
                    autocomplete="program-name"
                    v-model="this.formStudentInfo.selectedProgram"
                    class="mt-3 block w-full border border-sky-100 bg-white p-2 2xl:p-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option
                      disabled
                      selected
                      value="select"
                      class="bg-sky-100 text-sky-600"
                    >
                      Select Program
                    </option>
                    <option
                      v-for="el in this.programs"
                      :key="el"
                      :value="el.meta_info.name"
                      :selected_val="el.id"
                      class="bg-sky-100 text-sky-600"
                    >
                      {{ el.meta_info.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <div>
                    <label
                      for="elective"
                      class="block text-gray-700 font-medium text-base"
                      >Select Elective
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      required
                      id="elective"
                      name="electiveName"
                      autocomplete="elective-name"
                      v-model="this.formStudentInfo.selectedElective"
                      class="mt-3 block w-full border border-sky-100 bg-white p-2 2xl:p-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    >
                      <option disabled selected>Select Elective</option>
                      <option
                        v-for="el in this.electives"
                        :key="el"
                        :value="el.meta_info.name"
                        class="bg-sky-100 text-sky-600"
                      >
                        {{ el.meta_info.name }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="['MBA', 'MCA'].includes(this.selectedProgramVal)"
                    class="w-full px-3 flex gap-2 items-center relative"
                  >
                    <input
                      type="checkbox"
                      id="degreeEligibilty"
                      class="text-white accent-sky-400"
                      v-model="this.formStudentInfo.selectedEligibility"
                    />
                    <label for="degreeEligibilty">Eligibility</label>
                    <img
                      src="../../assets/info.png"
                      alt="info-icon"
                      class="h-4 cursor-pointer"
                    />
                    <!-- <div class="absolute top-10 rounded-md bg-[#001c54] text-white font-medium p-3 text-justify disclaimer_text hidden text-base">
                      <p>
                        Pass in an Undergraduate (Bachelor) Program of a minimum duration of three (3) years in
                        any stream from a UGC Recognized University, with a minimum aggregate of 50% or an equivalent letter/numerical grade.
                        A relaxation of 5% shall be given to SC/ST candidates. </p>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  v-if="['MBA', 'MCA'].includes(this.selectedProgramVal)"
                  class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  Pass in an Undergraduate (Bachelor) Program of a minimum
                  duration of three (3) years in any stream from a UGC
                  Recognized University, with a minimum aggregate of 50% or an
                  equivalent letter/numerical grade. A relaxation of 5% shall be
                  given to SC/ST candidates.
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-5xl md:text-7xl text-gray-300"
                    @click="selectStep(1)"
                  />
                  <div
                    v-if="
                      this.formStudentInfo.selectedProgram &&
                      this.formStudentInfo.selectedElective
                    "
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Select Your Nationality
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="nationality"
                    class="w-full text-base font-medium font-medium"
                    >Nationality <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="nationality"
                    name="nationality"
                    v-model="this.formStudentInfo.selectedNationality"
                    class="mt-2 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="nationality in this.nationality_val"
                      :key="nationality"
                      :value="nationality.name"
                    >
                      {{ nationality.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
              </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div v-if="this.formStudentInfo.selectedNationality">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Gender / Name & DOB as per
                  <span
                    v-if="this.formStudentInfo.selectedNationality == 'Indian'"
                    >10th marksheet </span
                  ><span v-else>Passport </span>
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="gender" class="w-full text-base font-medium"
                    >Gender <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="gender"
                    name="gender"
                    v-model="this.formStudentInfo.selectedGender"
                    class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="gender in gender_val"
                      :key="gender"
                      :value="gender.name"
                    >
                      {{ gender.name }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-10">
                  <div>
                    <label
                      for="nameAsPerSecondary"
                      class="w-full text-base font-medium"
                      >Name as per
                      <span
                        v-if="
                          this.formStudentInfo.selectedNationality == 'Indian'
                        "
                        >10th marksheet </span
                      ><span v-else>Passport </span>
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="name_asper_secondary"
                      id="nameAsPerSecondary"
                      v-model="this.formStudentInfo.selectedKycName"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    />
                  </div>
                  <div>
                    <label for="dob" class="w-full text-base font-medium"
                      >Date of Birth as per
                      <span
                        v-if="
                          this.formStudentInfo.selectedNationality == 'Indian'
                        "
                        >10th marksheet </span
                      ><span v-else>Passport </span>
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      required
                      type="date"
                      id="dob"
                      name="dob"
                      v-model="this.formStudentInfo.selectedKycDob"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
              </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(2)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFour"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">Guardian Detail</p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="guardianName" class="w-full text-base font-medium"
                    >Name of the Guardian / Spouse
                    <small class="text-red-500">*</small></label
                  >
                  <input
                    required
                    type="text"
                    id="guardianName"
                    name="guardian_name"
                    v-model="this.formStudentInfo.selectedGaurdianName"
                    class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  />
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <div>
                    <label
                      for="alterCountryCode"
                      class="w-full text-base font-medium"
                      >Alternate Mobile Number
                      <small class="text-red-500">*</small></label
                    >
                    <div
                      class="flex flex-row justify-start border border-sky-100 p-0 rounded-md overflow-hidden mt-1"
                    >
                      <select
                        required
                        id="alterCountryCode"
                        name="alterCountryCode"
                        autocomplete="alterCountryCode"
                        v-model="this.formStudentInfo.selectedAlterNumberCode"
                        class="w-auto block border-r border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-base"
                      >
                        <option value="91">+91</option>
                        <option value="882">+882</option>
                        <option value="452">+452</option>
                      </select>
                      <input
                        required
                        minlength="10"
                        maxlength="10"
                        type="text"
                        name="mobile_number_secontry"
                        id="mobileNumberSecontry"
                        v-model="this.formStudentInfo.selectedAlterNumber"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        class="block w-full bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-base"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
              </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(3)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFive"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">Higher Education</p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="HighestEducation"
                    class="w-full text-base font-medium"
                    >Highest educational degree
                    <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="HighestEducation"
                    name="highest_education"
                    v-model="this.formStudentInfo.selectedHighestEducation"
                    class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="highest_education in this.highest_education_list"
                      :key="highest_education"
                      :value="highest_education[0]"
                    >
                      {{ highest_education[0] }}
                    </option>
                  </select>
                </div>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <div>
                    <label
                      for="HighestEducationLocation"
                      class="w-full text-base font-medium"
                      >Highest educational degree location
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      required
                      id="HighestEducationLocation"
                      name="highest_education_location"
                      v-model="
                        this.formStudentInfo.selectedHighestEducationLocation
                      "
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="country in this.country_list"
                        :key="country"
                        :value="country.name"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
              </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(6)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSix"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  For discount / Scholarship
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <label
                    for="is_physicallyChallenged"
                    class="w-full text-base font-medium"
                    >Are you physically challenged?
                    <small class="text-red-500">*</small></label
                  >
                  <select
                    required
                    id="is_physicallyChallenged"
                    name="is_physically_challenged"
                    v-model="this.formStudentInfo.selectedDisabled"
                    class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                  >
                    <option selected disabled value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedNationality == 'Indian'"
                  class="w-full md:w-1/2 text-start flex flex-col gap-2"
                >
                  <div>
                    <label for="isMilitary" class="w-full text-base font-medium"
                      >Are You Eligible For Military Scholarship / Discount?
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      :required="
                        this.studentBasicInfo.selectedNationality == 'Indian'
                          ? true
                          : false
                      "
                      id="isMilitary"
                      name="is_Military"
                      v-model="this.formStudentInfo.selectedMilitary"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400 text-base"
                    >
                      <option selected disabled value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  v-if="this.formStudentInfo.selectedNationality == 'Indian'"
                  class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl"
                >
                  * Disclaimer
                </div>
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                  <button
                    type="submit"
                    class="h-10 text-base font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Save & Continue
                  </button>
                  <!-- <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" @click="selectStep(6)"/> -->
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-start rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-base"
            >
              <div class="flex flex-col gap-4">
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-user-circle"
                      class="text-xl pr-2"
                    />
                    Name:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.fullName }}
                  </p>
                </div>
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-at"
                      class="text-xl pr-2"
                    />Email:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.emailAddress }}
                  </p>
                </div>
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-mobile"
                      class="text-xl pr-2"
                    />
                    Phone:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.CodePrimary }}
                    {{ this.formStudentInfo.mobilePrimary }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedProgram"
                  class="flex flex-row gap-4 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-book"
                      class="text-xl pr-2"
                    />
                    Program:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedProgram }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedElective"
                  class="flex flex-row gap-4 items-start justify-start"
                >
                  <p
                    class="text-[#01215c] font-medium flex gap-2 justify-start items-start"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-bookmark"
                      class="text-xl pr-2"
                    />
                    <span>Elective:</span>
                  </p>
                  <p class="font-light text-[#01215c] text-justify">
                    {{ this.formStudentInfo.selectedElective }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedNationality"
                  class="flex flex-row gap-4 items-start"
                >
                  <p class="text-[#01215c] font-medium flex items-start">
                    <font-awesome-icon
                      icon="fa-solid fa-flag"
                      class="text-xl pr-2"
                    />
                    Nationality :
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedNationality }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row px-8 bg-pink-200">
          <div class="w-full 2xl:w-3/5 flex flex-row justify-between px-2">
            <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c]" />
            <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" />
          </div>
        </div> -->
        </div>
      </form>
      <!-- <img src="../../assets/background.png" alt="background" class="absolute inset-0"> -->
    </div>
  </div>
</template>

<script>
// import Navigation from '@/components/NavigationView.vue'
import Tracking from "@/components/TrackingView.vue";
// import LeftNavigation from '@/components/FormLeftNavView.vue'
import axios from "axios";
import $ from "jquery";
export default {
  name: "BasicInfoForm",
  data() {
    return {
      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,
      isStepSix: false,

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
        selectedProgram: "",
        selectedElective: "",
        selectedEligibility: "",
        selectedNationality: "",
        selectedGender: "",
        selectedKycName: "",
        selectedKycDob: "",
        selectedGaurdianName: "",
        selectedAlterNumberCode: "",
        selectedAlterNumber: "",
        selectedHighestEducation: "",
        selectedHighestEducationLocation: "",
        selectedDisabled: "",
        selectedMilitary: "",
      },

      isPopup: false,
      programs: [],
      electives: [],
      leadList: [],
      // find_nationality: "Select",
      // is_experience: "Select",
      // is_fatherlate: "select",
      // is_motherlate: "select",
      is_already_done: false,
      program_list: [],
      // studentBasicInfo: {},
      gender_val: [],
      nationality_val: [],
      ugDegree_val: [],
      country_list: [],
      highest_education_list: [],
      selectedProgramVal: "",
    };
  },
  props: ["id"],
  components: {
    // Navigation,
    Tracking,
    // LeftNavigation
  },

  async created() {
    await this.programCall();
    await this.genderCall();
    await this.nationalityCall();
    await this.ugDegreeCall();
    await this.countryCall();
    await this.highestEducation();

    if (
      localStorage.getItem("Tracking") &&
      localStorage.getItem("studentInfo")
    ) {
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));
      let studentInfo = JSON.parse(localStorage.getItem("studentInfo"));
      this.studentBasicInfo = studentInfo.formsInfo.basicInfo
        ? studentInfo.formsInfo.basicInfo.basicInfo_formdata
        : [];
      if (Tracking["basic"] == true) {
        this.is_already_done = true;
      }
    } else {
      let tracking = {
        basic: false,
        contact: false,
        eduaction: false,
        fee: false,
        document: false,
      };
      let studentId = {
        student_id:
          this.id == "" ? "f11a2523-2f3d-44d9-9979-f2642f8b4cfd" : this.id,
        formsInfo: {},
      };

      localStorage.setItem("Tracking", JSON.stringify(tracking));
      localStorage.setItem("studentInfo", JSON.stringify(studentId));
    }
  },
  methods: {
    disclaimer() {
      console.log("ese oin");
      $(".disclaimer_text").toggle("slow");
    },
    async highestEducation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/highest-qualification-degree-types`
        )
        .then((resp) => {
          this.highest_education_list = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async countryCall() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/country`
        )
        .then((resp) => {
          this.country_list = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ugDegreeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/ug-degree-types`
        )
        .then((resp) => {
          this.ugDegree_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async nationalityCall() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/nationality`)
        .then((resp) => {
          this.nationality_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async genderCall() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/genders`)
        .then((resp) => {
          this.gender_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async selectElective(el) {
      let selectedProgram =
        el.target.selectedOptions[0].attributes.selected_val.value;
      this.selectedProgramVal = el.target.value;
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/electives?batch=6BDQW5X4ENO&program_id=${selectedProgram}`
        )
        .then((resp) => {
          this.electives = resp.data.results;
        });
    },

    async programCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/programs?batch=9QOYNV5N18U`
        )
        .then((resp) => {
          this.programs = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    basicInfoPost() {
      console.log(this.formStudentInfo, "post");
      let basicInfoStatus = {
        basicInfo_formdata: this.formStudentInfo,
        submitTime: new Date(),
      };

      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));
      getStudentVal["formsInfo"]["basicInfo"] = basicInfoStatus;
      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));
      Tracking["basic"] = true;
      localStorage.setItem("Tracking", JSON.stringify(Tracking));
      this.$router.push({ name: "FeeInfoForm" });
    },
    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
      } else if (step == 6) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = true;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>

<style src="@/assets/css/ProgressTracking.css"></style>
