<template>
    <div class="w-full bg-white min-h-screen mx-auto">
      <Navigation />
      <div class="max-w-7xl mx-auto p-2">
        <div class="flex flex-row h-full">
          <LeftNavigation page="Rejected" />
          <div class="w-4/5 flex flex-col gap-8 mx-auto overflow-auto h-[90vh] border border-gray-300 rounded-lg shadow-lg">
            Document Dashboard Document Rejected Lead page
          </div>  
        </div>  
      </div>  
    </div>
</template>
    
<script>
import Navigation from '@/components/NavigationView.vue'
import LeftNavigation from '@/components/DocumentLeftNavView.vue'
export default {
    name: 'DocumentRejectedView',
    components: {
    Navigation,
    LeftNavigation,
    }
}
</script>
