<template>
  <div class="h-20 w-full flex flex-col justify-center bg-[#01215c] border-b border-sky-700">
    <div class="flex gap-4 justify-between px-6 items-center  w-full 2xl:max-w-7xl mx-auto">
        <div class="flex flex-row items-center justify-center">
            <img src="../assets/jainlogo.webp" alt="" class="h-9">
        </div>
        <div class="flex gap-3">
            <router-link :to="{name:'home'}">
                <button class="px-4 py-1 border-2 border-gray-400 rounded-md bg-white">Home</button>
            </router-link>
            <router-link :to="{name:'MyLead'}">
                <button class="px-4 py-1 border-2 border-gray-400 rounded-md bg-white">Lead</button>
            </router-link>
            <router-link :to="{name:'OverView'}">
                <button class="px-4 py-1 border-2 border-gray-400 rounded-md bg-white">Document</button>
            </router-link>
            <router-link :to="{name:'application'}">
                <button class="px-4 py-1 border-2 border-gray-400 rounded-md bg-white">Student</button>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
