<template>
  <div class="w-12 md:w-[220px]">
    <div class="flex flex-col h-[90vh] bg-[#01215c] text-white text-xs xl:text-sm">
      <router-link :to="{name:'application'}">
        <button class="w-full flex flex-row items-center gap-2 p-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]"
                :class="[this.page=='application' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          <p class="hidden md:block align-baseline mt-2">Dashboard</p>
        </button>
      </router-link>
      <router-link :to="{name:'BasicInfoForm'}">
        <button class="w-full flex flex-row justify-center md:justify-start items-center whitespace-nowrap gap-2 p-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 hover:bg-[#fcb21f] hover:text-[#01215c]"
                :class="[this.page=='basicInfo' ? 'bg-[#fcb21f] text-[#01215c]' : '']">
                <font-awesome-icon icon="fa-solid fa-circle-user" class="text-xl"/> 
                <p class="hidden md:block font-medium"> Personal Information</p>
        </button>
      </router-link>
      <router-link :to="{name:'FeeInfoForm'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]"
                :class="[this.page=='feeInfo' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Fee Payment
        </button>
      </router-link>
      <router-link :to="{name:'KycInfoForm'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]"
                :class="[this.page=='kycInfo' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          KYC Data
        </button>
      </router-link>
      <router-link :to="{name:'communicationForm'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" :class="[this.page=='experienceInfo' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Contact Information
        </button>
      </router-link>
      <router-link :to="{name:'EducationInfoForm'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]"
                :class="[this.page=='educationInfo' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Academic Details
        </button>
      </router-link>
      <router-link :to="{name:'DocumentUploadForm'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]"
                :class="[this.page=='documentInfo' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Document Upload
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
props:["page"]
}
</script>

<style>

</style>
