<template>
  <div class="w-[220px]">
    <div class="flex flex-col h-[90vh] bg-[#01215c] text-white text-sm rounded-lg overflow-hidden">
      <router-link :to="{name:'MyLead'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='MyLead' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Overview
        </button>
      </router-link>

      <router-link :to="{name:'Leads'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='Leads' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Leads
        </button>
      </router-link>
      <router-link :to="{name:'Enrolled'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='Enrolled' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Enrolled
        </button>
      </router-link>
      <router-link :to="{name:'RejectedLeads'}">
        <button class="w-full px-4 py-3 border-b border-sky-700 hover:border-b-2 hover:border-sky-600 text-start hover:font-bold hover:bg-[#fcb21f]" 
                :class="[this.page=='RejectedLeads' ? 'bg-[#fcb21f] text-[#01215c] font-bold' : '']">
          Rejected
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
props:["page"]
}
</script>

<style>

</style>